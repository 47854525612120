export const userData = [
    {
      name: "Jan",
      "Active User": 4000,
    },
    {
      name: "Feb",
      "Active User": 3000,
    },
    {
      name: "Mar",
      "Active User": 5000,
    },
    {
      name: "Apr",
      "Active User": 4000,
    },
    {
      name: "May",
      "Active User": 3000,
    },
    {
      name: "Jun",
      "Active User": 2000,
    },
    {
      name: "Jul",
      "Active User": 4000,
    },
    {
      name: "Agu",
      "Active User": 3000,
    },
    {
      name: "Sep",
      "Active User": 4000,
    },
    {
      name: "Oct",
      "Active User": 1000,
    },
    {
      name: "Nov",
      "Active User": 4000,
    },
    {
      name: "Dec",
      "Active User": 3000,
    },
  ];

  export const productData = [
    {
      name: "Jan",
      "Sales": 4000,
    },
    {
      name: "Feb",
      "Sales": 3000,
    },
    {
      name: "Mar",
      "Sales": 5000,
    },
  ];

  export const userRows = [
    {
      id: 1,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 2,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 3,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 4,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 5,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 6,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 7,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 8,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 9,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
    {
      id: 10,
      username: "Jon Snow",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwNVENtvfCc9mZkv88k1SMnDfIoYJK2a1AqLJ9LH0eSw&s",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
    },
  ];

  export const productRows = [
    {
      id: 1,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 2,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 3,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 4,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 5,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 6,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 7,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 8,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 9,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
    {
      id: 10,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
  ];

  export const orders = [
    {
      _id:  1233,
      userId: 5678,
      createdAt: "7 Jun 2021",
      amount: "$ 500"

    },
    {
      _id:  1334,
      userId: 56781,
      createdAt: "2 Jun 2022",
      amount: "$ 530"

    },
    {
      _id:  12345,
      userId: 5678,
      createdAt: "2 July 2022",
      amount: "$ 700"

    },
    {
      _id:  12348,
      userId: 56785,
      createdAt: "8 Aug 2021",
      amount: "$ 100"

    }
  ]