import React from 'react'
// import { orders } from '../../dummyData'
import './widgetLg.css';
import { useState } from 'react';
import { useEffect } from 'react';
// import {format} from 'timeago.js';
import { userRequest } from '../../requestMethods';



export default function WidgetLg() {

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await userRequest.get("orders");
                setOrders(res.data);
            } catch { }
        }
        getOrders();
    }, [])

    const Button = ({ type }) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
    };

    return (
        <div className="widgetLg">
            <h3 className="widgetLgTitle">Latest transactions</h3>
            <table className="widgetLgTable">
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Customer</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Amount</th>
                    <th className="widgetLgTh">Status</th>
                </tr>
                {orders.map((order) => (
                    <tr className="widgetLgTr" key={order._id}>
                        <td className="widgetLgUser">
                            <span className="widgetLgName">{order.userId}</span>
                        </td>
                        <td className="widgetLgDate">{order.createdAt}</td>
                        <td className="widgetLgAmount">${order.amount}</td>
                        <td className="widgetLgStatus">
                            <Button type={order.status} />
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    )
}
